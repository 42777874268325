let chat_users =
  localStorage.getItem('chat_users')?.split(',') ??
  'شنغوف هيمان عصيري الحري قرطاس عبسود سبرسو ريحان شنغوف بقعير كيوما فانتم منقول بنزير ماداو ممادو كوبان طويلب اولفا فطيسة شرهوش هروين كينشي كظومي عربون اهواك ديفرو اسماء ورلاك عميرة حموضة جقروم تينشي اتماد ارقام رسناب معيوف طقروخ بوعتب شيبوب ساؤول مجلود منتوق'.split(
    ' '
  )
const original_users =
  'شنغوف هيمان عصيري الحري قرطاس عبسود سبرسو ريحان شنغوف بقعير كيوما فانتم منقول بنزير ماداو ممادو كوبان طويلب اولفا فطيسة شرهوش هروين كينشي كظومي عربون اهواك ديفرو اسماء ورلاك عميرة حموضة جقروم تينشي اتماد ارقام رسناب معيوف طقروخ بوعتب شيبوب ساؤول مجلود منتوق'.split(
    ' '
  )
const update_chat_users = (updated_users: string[]) => {
  chat_users = Array.from(updated_users)
  localStorage.setItem('chat_users', updated_users.join(','))
}
const reset_chat_users = () => {
  update_chat_users(original_users)
}

export { chat_users, update_chat_users, reset_chat_users }
