import {
  HARD_MODE_DESCRIPTION,
  HIGH_CONTRAST_MODE_DESCRIPTION,
} from '../../constants/strings'
import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
  handleunlimited: Function
  isunlimited: boolean
  handleMastermind: (value: boolean) => void
  ismastermind: boolean
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
  handleunlimited,
  isunlimited,
  handleMastermind,
  ismastermind,
}: Props) => {
  return (
    <BaseModal title="الإعدادات" isOpen={isOpen} handleClose={handleClose}>
      <div className="mt-2 flex flex-col divide-y">
        <SettingsToggle
          settingName="الوضع الصعب"
          flag={isHardMode}
          handleFlag={handleHardMode}
          description={HARD_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="الوضع غير المحدود"
          flag={isunlimited}
          handleFlag={handleunlimited}
        />
        <SettingsToggle
          settingName="وضع MasterMind"
          flag={ismastermind}
          handleFlag={handleMastermind}
        />
        <SettingsToggle
          settingName="الوضع الليلي"
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName="ألوان متباينة"
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        />
      </div>
    </BaseModal>
  )
}
