import {
  CalendarIcon,
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
  UserGroupIcon, // UserGroupIcon, ////////// for arabian  only
} from '@heroicons/react/outline'

import { ENABLE_ARCHIVED_GAMES } from '../../constants/settings'
import { GAME_TITLE } from '../../constants/strings'
import { getWordOfDay } from '../../lib/words'

type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsDatePickerModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
  setIsUpdateUsersOpen: (value: boolean) => void ///////////// for MAD
  updateSolution: (value?: any) => void ///////////// for MAD
  removeGuesses: (value?: any) => void ///////////// for MAD
  setIsGameLost: (value: boolean) => void ///////////// for MAD
  setIsGameWon: (value: boolean) => void ///////////// for MAD
  setSolved: (value: number[]) => void ///////////// for MAD
  removeCurrent: (value: string) => void ///////////// for MAD
  setCounter: (value: number) => void ///////////// for MAD
  updateUnlimitedSolution: (value?: boolean) => void ///////////// for MAD
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsDatePickerModalOpen,
  setIsSettingsModalOpen,
  setIsUpdateUsersOpen,
  updateSolution,
  removeGuesses,
  setIsGameLost,
  setIsGameWon,
  setSolved,
  removeCurrent,
  setCounter,
  updateUnlimitedSolution,
}: Props) => {
  const updateGame = () => {
    updateSolution(getWordOfDay(0))
    updateUnlimitedSolution(true)
    removeGuesses([])
    removeCurrent('')
    setIsGameLost(false)
    setIsGameWon(false)
    setSolved([0, 0, 0, 0])
    setCounter(0)
  }

  return (
    <div className="navbar">
      <div className="navbar-content px-5 short:h-auto">
        <div className="flex gap-5">
          <InformationCircleIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
          {ENABLE_ARCHIVED_GAMES && false && (
            <CalendarIcon
              className="ml-3 h-6 w-6 cursor-pointer dark:stroke-white"
              onClick={() => setIsDatePickerModalOpen(true)}
            />
          )}
          {/* FOR ARABIAN MAD */}
          <UserGroupIcon
            className="ml-3 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsUpdateUsersOpen(true)}
          />
        </div>
        <button disabled={true} className="w-40"></button>
        <p className="text-xl font-bold dark:text-white">{GAME_TITLE}</p>
        <button
          className="h-10 w-40 rounded border bg-gray-500 bg-opacity-25 text-sm font-medium text-white hover:bg-opacity-50 "
          onClick={(e) => {
            updateGame()
            e.currentTarget.blur()
          }}
        >
          تحديث
        </button>
        <div className="right-icons gap-5">
          <ChartBarIcon
            className="mr-3 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsStatsModalOpen(true)}
          />
          <CogIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
