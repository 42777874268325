import './App.css'

import { ClockIcon } from '@heroicons/react/outline'
import { format } from 'date-fns'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useEffect, useState } from 'react'
import Div100vh from 'react-div-100vh'

import { AlertContainer } from './components/alerts/AlertContainer'
import { Grid } from './components/grid/Grid'
import { GridMastermind } from './components/grid/GridMastermind'
import { GridUnlimited } from './components/grid/GridUnlimited'
import { Keyboard } from './components/keyboard/Keyboard'
import { DatePickerModal } from './components/modals/DatePickerModal'
import { InfoModal } from './components/modals/InfoModal'
// import { MigrateStatsModal } from './components/modals/MigrateStatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { StatsModal } from './components/modals/StatsModal'
import { UsersModal } from './components/modals/UsersModal'
import { Navbar } from './components/navbar/Navbar'
import {
  DATE_LOCALE,
  DISCOURAGE_INAPP_BROWSERS,
  LONG_ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import {
  CORRECT_WORD_MESSAGE,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  GAME_COPIED_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  SHARE_FAILURE_TEXT,
  WIN_MESSAGES,
  WORD_NOT_FOUND_MESSAGE,
} from './constants/strings'
import { useAlert } from './context/AlertContext'
import { isInAppBrowser } from './lib/browser'
import {
  getStoredIsHighContrastMode,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
} from './lib/localStorage'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  // findFirstUnusedReveal,
  getGameDate,
  getIsLatestGame,
  getWordOfDay,
  isWinningWord,
  isWordInWordList,
  setGameDate, // solution,
  solutionGameDate,
  unicodeLength,
} from './lib/words'

function App() {
  const isLatestGame = getIsLatestGame()
  const gameDate = getGameDate()
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const [solution, setSolution] = useState(getWordOfDay(0))
  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false)
  // const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
  const setIsMigrateStatsModalOpen = (value?: boolean) => {} //////  quick solution
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
        ? true
        : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage(isLatestGame)
    if (loaded?.solution.join('') !== solution.join('')) {
      return []
    }

    const gameWasWon = solution.every((element) =>
      loaded.guesses.includes(element)
    )
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true)
      showErrorAlert(CORRECT_WORD_MESSAGE(solution[0]), {
        persist: true,
      })
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )
  const [solved, setSolved] = useState([0, 0, 0, 0])
  const [focus, setFocus] = useState(4)
  const [isUpdateUsersOpen, setIsUpdateUsersOpen] = useState(false) ///// for ARABIAN MAD
  const [loading, setLoading] = useState(
    localStorage.getItem('loading')
      ? !(localStorage.getItem('loading') === 'false')
      : true
  )

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage(true)) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  })

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 7000,
      })
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage(getIsLatestGame(), { guesses, solution })
  }, [guesses, solution])

  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      const delayMs = REVEAL_TIME_MS * solution.length

      showSuccessAlert(winMessage, {
        delayMs,
        // onClose: () => setIsStatsModalOpen(true),
      })
    }

    if (isGameLost) {
      setTimeout(
        () => {
          // setIsStatsModalOpen(true)
        },
        (solution.length + 1) * REVEAL_TIME_MS
      )
    }
  }, [isGameWon, isGameLost, showSuccessAlert, solution])

  const onChar = (value: string) => {
    if (loading) {
      if (value === 'ئ') {
        setCounter(counter + 1)
        if (counter === 2) {
          setCounter(0)
          setLoading(false)
          localStorage.setItem('loading', 'false')
        }
      } else {
        setCounter(0)
      }

      return
    }
    if (isUpdateUsersOpen) {
      ///// for ARABAIN MAD
      return
    }
    if (
      unicodeLength(`${currentGuess}${value}`) <= /*solution.length*/ 5 && // solution lengrh will be 4 as it's an array not a string
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === /*solution.length*/ 5)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    setTimeout(() => {
      setSolved(
        solution.map((solution_word) =>
          guesses.concat(currentGuess).includes(solution_word) ? 1 : 0
        )
      )
    }, 5 * 350)

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * /*solution.length*/ 5)

    const winningWord = isWinningWord(guesses.concat(currentGuess), solution)

    if (
      unicodeLength(currentGuess) === /*solution.length*/ 5 &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord && !isunlimit) {
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length))
        }

        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        }
        if (!isunlimit) {
          setIsGameLost(true)
        } else if (
          isunlimit &&
          !guesses.concat(currentGuess).includes(unlimitedsolution)
        ) {
          setIsGameLost(true)
        }
      }
    }
  }
  useEffect(() => {
    if (isHardMode) {
      setFocus(solved.includes(0) ? solved.indexOf(0) : 4)
    }
  }, [solved, isHardMode])

  const [isunlimit, setIsunlimit] = useState(
    localStorage.getItem('unlimited')
      ? localStorage.getItem('unlimited') === 'unlimited'
      : false
  )
  const [ismastermind, setIsmastermind] = useState(
    localStorage.getItem('mastermind')
      ? localStorage.getItem('mastermind') === 'mastermind'
      : false
  )

  const handleunlimited = (unlimitedCheck: boolean) => {
    if (unlimitedCheck) {
      setIsHardMode(false)
      localStorage.setItem('gameMode', 'normal')
      setIsmastermind(false)
      localStorage.setItem('mastermind', '')
    }
    setIsunlimit(unlimitedCheck)
    localStorage.setItem('unlimited', unlimitedCheck ? 'unlimited' : '')
  }
  const handlemastermind = (mastermindCheck: boolean) => {
    if (mastermindCheck) {
      setIsHardMode(false)
      localStorage.setItem('gameMode', 'normal')
      setIsunlimit(false)
      localStorage.setItem('unlimited', '')
    }
    setIsmastermind(mastermindCheck)
    localStorage.setItem('mastermind', mastermindCheck ? 'mastermind' : '')
  }
  const [unlimitedsolution, setUnlimitedSolution] = useState(solution[3])
  const [allclasses, setAllclasses] = useState(false)
  const [counter, setCounter] = useState(0)
  const [validate, setValidate] = useState(true)

  const updateUnlimitedSolution = (refresh?: boolean) => {
    if (!isunlimit) {
      return
    }
    let newWordlist: string[] = []
    while (newWordlist.length < 1) {
      newWordlist = getWordOfDay(0)
      newWordlist = newWordlist.filter((w) => !guesses.includes(w))
    }

    if (refresh) {
      setUnlimitedSolution(newWordlist[0])
    } else {
      setValidate(false)

      if (guesses.length > 8) {
        setTimeout(() => {
          setGuesses(guesses.slice(guesses.length - 8))
        }, 5 * 350)
      }
      setTimeout(() => {
        setUnlimitedSolution(newWordlist[0])
        setAllclasses(true)
        setCounter(counter + 1)
        setValidate(true)
      }, 6 * 350)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setAllclasses(false)
    }, 350 * 5)
  }, [allclasses])

  return (
    <>
      {loading ? (
        <>
          <h1 className="mb-auto mr-14 mt-auto w-full text-center text-6xl text-gray-300">
            ... Loading Content ...{' '}
          </h1>
          <div style={{ display: 'none' }}>
            <Keyboard
              onChar={onChar}
              onDelete={onDelete}
              onEnter={onEnter}
              solution={isunlimit ? [unlimitedsolution] : solution}
              guesses={guesses}
              isRevealing={isRevealing}
              focus={isunlimit ? 0 : focus}
              update_focus={setFocus}
              isHardMode={isHardMode}
            />
          </div>
        </>
      ) : (
        <Div100vh>
          <div className="flex h-full flex-col">
            <Navbar
              setIsInfoModalOpen={setIsInfoModalOpen}
              setIsStatsModalOpen={setIsStatsModalOpen}
              setIsDatePickerModalOpen={setIsDatePickerModalOpen}
              setIsSettingsModalOpen={setIsSettingsModalOpen}
              setIsUpdateUsersOpen={setIsUpdateUsersOpen} /////// for arabian MaD
              updateSolution={setSolution}
              removeGuesses={setGuesses}
              setIsGameLost={setIsGameLost}
              setIsGameWon={setIsGameWon}
              setSolved={setSolved}
              removeCurrent={setCurrentGuess}
              setCounter={setCounter}
              updateUnlimitedSolution={updateUnlimitedSolution}
            />

            {!isLatestGame && (
              <div className="flex items-center justify-center">
                <ClockIcon className="h-6 w-6 stroke-gray-600 dark:stroke-gray-300" />
                <p className="text-base text-gray-600 dark:text-gray-300">
                  {format(gameDate, 'd MMMM yyyy', { locale: DATE_LOCALE })}
                </p>
              </div>
            )}

            <div className="mx-auto flex w-full grow flex-col px-1 pb-8 pt-2 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
              <div className="grow- flex flex-row justify-center pb-6 short:pb-2">
                {isunlimit ? (
                  <>
                    <GridUnlimited
                      key={'unlimit-word'}
                      solution={unlimitedsolution}
                      guesses={guesses}
                      currentGuess={currentGuess}
                      isRevealing={isRevealing}
                      currentRowClassName={currentRowClass}
                      isGameDone={isGameLost}
                      hidden={false}
                      grid_id={0}
                      focus={0}
                      update_focus={setFocus}
                      isHardMode={true}
                      updateUnlimitedSolution={updateUnlimitedSolution}
                      allclasses={allclasses}
                      validate={validate}
                    />
                    <div className="counter mb-auto mr-14 mt-auto flex h-72 w-72 justify-center rounded bg-gray-800 bg-opacity-75 text-center text-gray-300">
                      <h1 className="mb-auto mt-auto">{counter}</h1>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {ismastermind ? (
                  <>
                    {' '}
                    {console.log(solution[1], solution[0])}
                    <GridMastermind
                      key={'mastermind-word1'}
                      solution={solution[0]}
                      guesses={guesses}
                      currentGuess={currentGuess}
                      isRevealing={isRevealing}
                      currentRowClassName={currentRowClass}
                      grid_id={0}
                      focus={2}
                    />
                    <GridMastermind
                      key={'mastermind-word2'}
                      solution={solution[1]}
                      guesses={guesses}
                      currentGuess={currentGuess}
                      isRevealing={isRevealing}
                      currentRowClassName={currentRowClass}
                      grid_id={0}
                      focus={2}
                    />
                  </>
                ) : (
                  <></>
                )}
                {!isunlimit && !ismastermind ? (
                  <>
                    {solution.map((sol, i) => (
                      <>
                        <Grid
                          key={i + 1 + '-word'}
                          solution={sol}
                          guesses={guesses}
                          currentGuess={currentGuess}
                          isRevealing={isRevealing}
                          currentRowClassName={currentRowClass}
                          isGameDone={isGameLost || isGameWon}
                          hidden={isHardMode && focus < i}
                          grid_id={i}
                          focus={focus}
                          update_focus={setFocus}
                          isHardMode={isHardMode}
                          updateUnlimitedSolution={() => {}}
                        />
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <Keyboard
                onChar={onChar}
                onDelete={onDelete}
                onEnter={onEnter}
                solution={
                  isunlimit
                    ? [unlimitedsolution]
                    : ismastermind
                      ? ['']
                      : solution
                }
                guesses={guesses}
                isRevealing={isRevealing}
                focus={isunlimit || ismastermind ? 0 : focus}
                update_focus={setFocus}
                isHardMode={isHardMode}
                isMastermind={ismastermind}
              />
              <InfoModal
                isOpen={isInfoModalOpen}
                handleClose={() => setIsInfoModalOpen(false)}
              />
              <StatsModal
                isOpen={isStatsModalOpen}
                handleClose={() => setIsStatsModalOpen(false)}
                solution={solution[0]}
                guesses={guesses}
                gameStats={stats}
                isLatestGame={isLatestGame}
                isGameLost={isGameLost}
                isGameWon={isGameWon}
                handleShareToClipboard={() =>
                  showSuccessAlert(GAME_COPIED_MESSAGE)
                }
                handleShareFailure={() =>
                  showErrorAlert(SHARE_FAILURE_TEXT, {
                    durationMs: LONG_ALERT_TIME_MS,
                  })
                }
                handleMigrateStatsButton={() => {
                  setIsStatsModalOpen(false)
                  setIsMigrateStatsModalOpen(true)
                }}
                isHardMode={isHardMode}
                isDarkMode={isDarkMode}
                isHighContrastMode={isHighContrastMode}
                numberOfGuessesMade={guesses.length}
              />
              <DatePickerModal
                isOpen={isDatePickerModalOpen}
                initialDate={solutionGameDate}
                handleSelectDate={(d) => {
                  setIsDatePickerModalOpen(false)
                  setGameDate(d)
                }}
                handleClose={() => setIsDatePickerModalOpen(false)}
              />

              <SettingsModal
                isOpen={isSettingsModalOpen}
                handleClose={() => setIsSettingsModalOpen(false)}
                isHardMode={isHardMode}
                handleHardMode={handleHardMode}
                isDarkMode={isDarkMode}
                handleDarkMode={handleDarkMode}
                isHighContrastMode={isHighContrastMode}
                handleHighContrastMode={handleHighContrastMode}
                handleunlimited={handleunlimited}
                isunlimited={isunlimit}
                handleMastermind={handlemastermind}
                ismastermind={ismastermind}
              />
              {/*  ////////////////////////////////////////////////////    for Arabian MAD only */}
              <UsersModal
                isOpen={isUpdateUsersOpen}
                handleClose={() => setIsUpdateUsersOpen(false)}
              />
              <AlertContainer />
            </div>
          </div>
        </Div100vh>
      )}
    </>
  )
}

export default App
