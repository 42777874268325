import { useEffect, useState } from 'react'

import {
  chat_users,
  reset_chat_users,
  update_chat_users,
} from '../../constants/chat_users'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const UsersModal = ({ isOpen, handleClose }: Props) => {
  const [chat, setChat] = useState(chat_users)
  const [new_user, setNewUser] = useState('')
  // const [add, setAdd] = useState(false)

  const del_u = (name: string) => {
    setChat(chat.filter((u) => u !== name))
  }

  const add_u = () => {
    if (
      new_user.length === 5 &&
      new_user.split('').every((u) => u <= 'ي' && u >= 'ء') &&
      !chat.includes(new_user)
    ) {
      setChat([...chat_users, new_user])
      setNewUser('')
      // setAdd(false)
    }
  }
  useEffect(() => {
    update_chat_users(chat)
  }, [chat])
  // useEffect(() => {
  //   if (
  //     !add ||
  //     new_user.length !== 5 ||
  //     !new_user.split('').every((u) => u <= 'ي' && u >= 'ء') ||
  //     chat.includes(new_user)
  //   ) {
  //     setAdd(false)

  //     return
  //   } else {

  //   }
  // }, [new_user, add, chat])

  return (
    <BaseModal title="اسماء الشات" isOpen={isOpen} handleClose={handleClose}>
      <div className="mt-2 flex flex-col">
        <div>
          {chat.map((u, i) => (
            <div
              key={i}
              className={`flex w-full justify-between  p-1 ${i % 2 === 0 ? '' : 'bg-gray-600 bg-opacity-25'} hover:bg-gray-700`}
            >
              <span className="text-sm font-medium text-white ">{u}</span>
              <button
                onClick={() => del_u(u)}
                className="w-8 rounded border text-sm font-medium text-white "
              >
                {' '}
                -
              </button>
            </div>
          ))}
          <div className="mt-2 flex w-full">
            <input
              autoComplete="off"
              value={new_user}
              name="new_user"
              onChange={(e) => {
                setNewUser(e.target.value)
              }}
              className="h-8 w-full bg-gray-700 text-white"
            />{' '}
            <button
              onClick={() => {
                add_u()
              }}
              className="w-8 rounded border text-sm font-medium text-white "
            >
              +
            </button>
          </div>
        </div>
        <div className="mt-2 flex flex-row gap-1 ">
          <button
            onClick={() => {
              reset_chat_users()
              setChat(chat_users)
            }}
            className="mt-2 inline-flex h-14 w-full items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
          >
            Reset
          </button>
          <button
            onClick={() => {
              navigator.clipboard.writeText(chat.join(','))
            }}
            className="mt-2 inline-flex h-14 w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
          >
            Copy to Clipboard
          </button>
          <button
            onClick={() => handleClose()}
            className="mt-2 inline-flex h-14 w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
          >
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  )
}
