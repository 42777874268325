export const GAME_TITLE = 'أربع كلمات'

export const WIN_MESSAGES = ['أحسنت!', 'مذهل!', 'رائع!']
export const GAME_COPIED_MESSAGE = 'نُسخت اللعبة إلى الحافظة'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'الأحرف قليلة!'
export const WORD_NOT_FOUND_MESSAGE = 'الكلمة لم تضف للقاموس بعد !'
export const HARD_MODE_ALERT_MESSAGE = 'يجب تفعيل الوضع الصعب في بداية اللعبة '
export const HARD_MODE_DESCRIPTION =
  'الكلمات تكون مخفية وتنكشف بالتوالي عند إصابة الكلمة السابقة'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'لتحسين التجربة البصرية'
export const CORRECT_WORD_MESSAGE = (solution: string) => `محاولة رائعة `
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Guess must contain ${letter}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'الإحصائيات'
export const GUESS_DISTRIBUTION_TEXT = 'توزيع الإجابات'
export const NEW_WORD_TEXT = 'كلمات جديدة خلال'
export const SHARE_TEXT = 'مشاركة'
export const SHARE_FAILURE_TEXT =
  'Unable to share the results. This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.'
export const MIGRATE_BUTTON_TEXT = 'Transfer'
export const MIGRATE_DESCRIPTION_TEXT =
  'Click here to transfer your statistics to a new device.'
export const TOTAL_TRIES_TEXT = 'مجموع المحاولات'
export const SUCCESS_RATE_TEXT = 'معدل النجاح'
export const CURRENT_STREAK_TEXT = 'النجاح المستمر'
export const BEST_STREAK_TEXT = 'أعلى نجاح متواصل'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser."

export const DATEPICKER_TITLE = 'الكلمات الماضية'
export const DATEPICKER_CHOOSE_TEXT = 'اختر'
export const DATEPICKER_TODAY_TEXT = 'اليوم'
export const ARCHIVE_GAMEDATE_TEXT = 'تاريخ اللعبة'
